import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// import Logo from '../../Assets/Images/logo.svg';
import Logo from '../../Assets/Images/logo_EL.png';
import Logo_M from '../../Assets/Images/Logo_Mobile.png';
import BleatLogo from '../../Assets/Images/logo_bleat_black_text.png';
// import churchOutreachLogo from '../../Assets/Images/logo-outreach.png';

const Header = props => {

  const { windowWidth } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAssessmentEmbedded, setIsAssessmentEmbedded] = useState(false);

  let display = searchParams.get("display");
  console.log("display:", display);

  useEffect(() => {
    if(display === "embedded" || display === "preview") {
      setIsAssessmentEmbedded(true);
    }
  }, [display]);

  // console.log(searchParams.get("display"));
  
  if (isAssessmentEmbedded) return <div style={{ margin: "0 auto", padding: "30px 0" }}></div>;
  else return <Fragment>
    <header>
      {/* <Link to="/start" className="logo"><img src={Logo} /></Link> */}
      <div className="assessment-title" id="assessment-title">
        { windowWidth > 830 ? <img src={Logo} alt="ELS Logo" className='leadership'/> : <img src={Logo_M} alt="ELS Logo" className='mob_lead' />}
        { windowWidth > 650 ? <p>CHURCH GROWTH SOLUTIONS ASSESSMENT</p> : <p>CHURCH GROWTH SOLUTIONS ASSESSMENT</p>}
        { windowWidth > 830 ? <img src={BleatLogo} alt="Bleat Logo" /> : null}
      </div>
    </header>
  </Fragment>
}

export default Header;
