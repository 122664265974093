import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import insightsIcon from '../Assets/Images/insights-icon.png';
import alignmentIcon from '../Assets/Images/alignment-icon.png';
import directionIcon from '../Assets/Images/direction-icon.png';
import Testimonial from './Testimonial';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const InviteInfo = (props) => {

  const { windowWidth } = useWindowDimensions();
  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this assessment</h3>
        <p>Gain new insights into your growth as a church by inviting your team to take this assessment.</p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={insightsIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Measure your church's impact in critical areas of ministry
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Begin to align around a strategy for growth as a team
          </div>

          <div className="reason">
            <img src={directionIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart the best course forward for your church
          </div>

        </div>

        {/* {windowWidth > 550 ? null : <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>} */}
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}

      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}
      
      {/* <div className="schedule-call">
        <h3>Connect with the Executive Leadership Solutions team</h3>
        <p>If you would like to dive deeper into your results, our team would be happy to help you better understand what’s causing your church’s growth barrier and what opportunities exist to move towards growth.</p>
        <div className="cta-btn">
          <a href="https://www.el.solutions/free-first-step?utm_campaign=Church%20Growth%20Assessment&utm_source=%3Dchurchgrowthassessment" target="_blank" rel="noopener noreferrer">
            <Button>SCHEDULE A CALL</Button>
          </a>
        </div>
      </div> */}
      
    </div>
  );
}

export default InviteInfo;
