import { useEffect, useState } from "react"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import prevBtnImg from "../../Assets/Images/prev.png";
import nextBtnImg from "../../Assets/Images/next.png";
import useWindowDimensions from '../../Hooks/useWindowDimensions';

// const titles = [
//   { id: 1, title: "Messaging", subheader: "Are you communicating effectively with your audiences?" },
//   { id: 2, title: "Experience", subheader: "Do you need to design a more engaging experience for your church?" },
//   { id: 3, title: "Metrics", subheader: "Are you prioritizing the need to measure your ministry and staff metrics? " },
//   { id: 4, title: "Data & Leadership", subheader: "Are you utilizing high-quality data reporting to support your vision and mission?" },
//   { id: 5, title: "Data Analysis Abilities", subheader: "Should leveling up to analyze your church's data be your #1 focus?" },
//   { id: 6, title: "Status Quo", subheader: "Do you need to redefine your church's indicators for progress and success?" },
//   { id: 7, title: "Data Desire", subheader: "Is it time to shift your focus to utilizing tools that drive better decision-making?" },
//   { id: 8, title: "Data & Source", subheader: "Do you need to better interpret data to translate this into actionable next steps?" },
//   { id: 9, title: "Data Methodology", subheader: "Do you need to change the way your church collects and analyzes data?" },
// ];

const DashboardIndividual = ({ dashboardIndividualProps }) => {

  const { selected, updateIndividualAssessment, members, memberScore, sections, memScores, memberSection1, memberSection2, memberSection3, memberSection4, toggleSection, toggleSectionDetails, questions } = dashboardIndividualProps;

  const { windowWidth } = useWindowDimensions();

  console.log("dashboardIndividualProps", dashboardIndividualProps);

  const titles = [
    { id: 1, title: "Organizational Alignment", score: memScores?.[0], 
      resultTitle: "your greatest area for growth is Organizational Alignment.", 
      resultText: ["Alignment in leadership outweighs the individual clarity of its leaders, as clarity is most meaningful when everyone has the same vision and is pointed in the same direction.", "For your church to maximize its opportunities in leading people closer to Jesus, leadership has to be completely aligned and clear in both why your church exists and who you are uniquely created to serve (identity) before it can move onto strategy."] },
    { id: 2, title: "Guest Experience", score: memScores?.[1], 
      resultTitle: "your greatest area for growth is Guest Experience.", 
      resultText: ["A more integrated approach to Sunday morning services and daily digital engagement could yield a higher level of overall engagement as you build closer relationships and meaningful discipleship pathways.", "This requires thinking about tailoring the experiences you are creating to maximize the medium of experience, tailoring each experience to what it is meant to accomplish."] },
    { id: 3, title: "Measuring Impact", score: memScores?.[2], 
      resultTitle: "your greatest area for growth is Measuring Impact.", 
      resultText: ["The better you are at understanding who it is you are trying to reach and how to reach them, your church needs to have clear metrics and data to constantly evaluate what is working and what is not.", "Data does not create answers but it does help us form the questions that inform our decisions. Constant evaluation and course correction accelerates our ability to create meaningful relationships, which is much harder when clear data is not available to us."] },
    { id: 4, title: "Building Activation", score: memScores?.[3], 
      resultTitle: "your greatest area for growth is Building Activation.", 
      resultText: ["Money and buildings are great servants, but terrible masters. The ability for money to be a servant to your ministry and not dictate the decisions you have to make is based on your ability to create and execute financial and real estate strategy.", "For your church to be successful in using its assets to accelerate ministry, we have to consider how we are utilizing our biggest assets."] },
  ];

  const [toggleState, setToggleState] 					= useState(1);
  const [currentTabIdx, setCurrentTabIdx]				= useState(0);

  useEffect(() => {
		setToggleState(currentTabIdx + 1);
	}, [currentTabIdx]);

  const toggleSectionDisplay = (id) => {
    if (id === toggleSection) {
      return { display: "block" }
    } else {
      return { display: "none" }
    }
  }

  const toggleSectionIcon = (id) => {
    if (id === toggleSection) {
      return <RemoveIcon />
    } else {
      return <AddIcon />
    }
  }

  const showAnswerText = (answer, questionId) => {

    let question = questions?.find(function (obj) { return obj?.id === questionId; });
    let answerType = "green";

    if (question?.answers?.length === 2) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "green"; }
    } else if (question?.answers?.length === 3) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "orange"; }
      else if (answer === 3) { answerType = "green"; }
    } else if (question?.answers?.length === 4) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "red"; }
      else if (answer === 3) { answerType = "green"; }
      else if (answer === 4) { answerType = "green"; }
    } else if (question?.answers?.length === 5) {
      if (answer === 5) { answerType = "red"; }
      else if (answer === 4) { answerType = "red"; }
      else if (answer === 3) { answerType = "orange"; }
      else if (answer === 2) { answerType = "green"; }
      else if (answer === 1) { answerType = "green"; }
    }
    let answerVal = question?.answers?.find(function (obj) { return obj.value === answer; });

    if (answerVal && answerVal.desktop_text) {
      if (answerType === "red") {
        return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      } else if (answerType === "orange") {
        return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      } else if (answerType === "green") {
        return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      }
    }

  }

  // const calcLeft = (subscore) => {
  //   return subscore === 100 ? (subscore - 61) : subscore < 15 ? (-42) : (subscore - 60);
  // }

  const nextTab = () => {
		let sectionsLen = sections?.length;
		if(currentTabIdx < sectionsLen - 1) {	
			setCurrentTabIdx((prev) => prev + 1);
		} else setCurrentTabIdx(0);
	}
	
	const prevTab = () => {
		let sectionsLen = sections?.length;
		if(currentTabIdx > 0) {	
			setCurrentTabIdx((prev) => prev - 1);
		} else setCurrentTabIdx(sectionsLen - 1);
	}
  
  const selectedMember = members?.find(m => m.id === selected);
  const selectedMemberSubScores = [ selectedMember?.subScore1, selectedMember?.subScore2, selectedMember?.subScore3, selectedMember?.subScore4, selectedMember?.subScore5, selectedMember?.subScore6, selectedMember?.subScore7, selectedMember?.subScore8, selectedMember?.subScore9 ];
  
  console.log("selected", selected); console.log("selectedMember", selectedMember);

  const unsortedSubscores = selectedMemberSubScores;
  const unsortedSubscoresWithTitles = titles.map((item, index) => {
    return { ...item, subscore: unsortedSubscores[index] }
  });
  
  const sortedSubscoresWithTitles = unsortedSubscoresWithTitles.sort((a, b) => b?.subscore - a?.subscore);
  // console.log("unsortedSubscoresWithTitles", unsortedSubscoresWithTitles);
  console.log("sortedSubscoresWithTitles", sortedSubscoresWithTitles);
  console.log("members", members);
  console.log("questions", questions);
  console.log("sections", sections);

  const answerNumToText = (num) => {
    let selectedOption = "";
    switch (num) {
      case 1: selectedOption = "Strongly Disagree"; break;
      case 2: selectedOption = "Disagree"; break;
      case 3: selectedOption = "Neutral"; break;
      case 4: selectedOption = "Agree"; break;
      case 5: selectedOption = "Strongly Agree"; break;
      default: selectedOption = "Answer Unavailable"; break;
    }
    return selectedOption;
  }

  let selectedMemberData = sections?.map(section => ({ sectionName: section.section, sectionId: section.id }))
  selectedMemberData?.forEach(section => {
    let sectionQs = questions?.filter(q => q.sectionId === section.sectionId);
    section.questions = sectionQs?.map(sQ => ({ question: sQ.question, questionId: sQ.id }))
  })
  
  const selectedMemberAnswers = selectedMember?.answers;

  selectedMemberData?.forEach(section => {
    section.questions?.forEach(sectQ => {
      let ans = selectedMemberAnswers?.find((answer) => answer.question_id === sectQ.questionId);
      sectQ.answerTxt = answerNumToText(ans.answer);
      sectQ.answerNum = ans.answer;
    })
  })

  const answerTextColors = (num) => {
    switch (num) {
      case 1: return "answer-text red";
      case 2: return "answer-text red";
      case 3: return "answer-text yellow";
      case 4: return "answer-text green";
      case 5: return "answer-text green";
      default: return "answer-text";
    }
  }

  const showResultText = () => {
    return sortedSubscoresWithTitles?.[0]?.resultText?.map(text => <p>{text}</p>)
  }

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: selectedMember[`subScore${idx + 1}`] }));
  // const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section }));
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const individualAnswer = [];

  const individualMember = members?.find(m => m.id == selected);
	let question = individualMember?.answers?.find(a => a.question_id == 426);
	if (question?.answer_txt) individualAnswer.push(question?.answer_txt);
  
  //remove html tags
  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
    str = str.toString();
    return str.replace(/<[^>]+>/ig, '');
  }

  console.log("selectedMemberData", selectedMemberData);
  console.log("selected", selected);
  console.log("individualAnswer", individualAnswer);

  const calcLeft = (subscore) => {
    // return subscore >= 80 ? (-38) : subscore < 15 ? (-42) : ((Math.round((380- (4*subscore))/3)) - 60);
    if(subscore === 100) return `calc(${subscore - 4}% - 4ch)`;
    else if(subscore <= 15) return `${2.5}%`;
    else return `calc(${subscore}% - 4ch)`;
  }

  const percentCalc=(subscore) =>{
    if(subscore >= 80)
    {
      return 20;
    }
    else{ 
      return Math.round((380- (4*subscore))/3);
    }
  } 

  return (
    <div className="container results">

      <div style={{ height: '20px', marginTop: "45px" }}></div>
      <div className="selection-form">
        <div className="form-detail">
          <TextField select hiddenLabel value={selected} onChange={(e) => { updateIndividualAssessment(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
            {members?.map((option) => (
              <MenuItem key={option.id} value={option?.id}>
                {option?.fullName}'s
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="form-text">assessment score</div>
      </div>

      <div className="details-container">

        {/* <div className="progress-bar">
          <div className="si_ass_result">
            <div className="si_ass_progress">
              <div className="progress-line" style={{ left: memberScore + '%' }}></div>
              <span className="msi_score" style={{ left: memberScore + '%' }}>{memberScore}%</span>
            </div>
            <div className="pervalue">
              <h4 className="nimp" style={{ minWidth: '45%' }}></h4>
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
            </div>
            <div className="si_pro_header">
              <h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br />improvement</h4>
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br />expectations</h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br />expectations</h4>
            </div>
          </div>
        </div> */}

        {/* <p className="result-intro">We're hopeful this report will help you celebrate areas of success and identify areas of opportunity for stronger alignment as a church team!</p> */}

        <h1 style={{ fontSize: windowWidth < 651 ? "20px" : "36px", paddingTop: 0 }}>{selectedMember?.fullName?.split(" ")?.[0].trim() + ", " + sortedSubscoresWithTitles?.[0]?.resultTitle}</h1>

        <div className="result-text">{showResultText()}</div>

        <div className="result-sections individual">

          <div className="individual-scores-container">

          {sortedSubscoresWithTitles?.map((item, index) => {
            return (
              <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
                <div className="result-section-title"><p><span>{index + 1}.</span> {windowWidth > 550 ? <>&nbsp;&nbsp;&nbsp;</> : null}{item?.title}</p></div>
                <div className="score-progress-bar">

                  <LinearProgress variant="determinate" value={item?.subscore} />
                  <span className="score-inside-progress-bar" style={{ position: "absolute", textAlign: "left", left: calcLeft(item?.subscore), top: "0px", margin: 0, zIndex: 999999, fontWeight: 700, fontSize: "1rem", color: item?.subscore <= 5 ? "#343333" : "#FFFFFF" }}>{item?.subscore}%</span>

                </div>
              </div>
            )
          })}
          </div>

      {/* <h2 style={{ textAlign: "center", marginTop: "75px" }}>INDIVIDUAL SCORES</h2>
      <div className="selected-member-data">
        <div className="tab-change">
          <p>{currentTabIdx + 1}/{sections?.length}</p>
          <div className="tab-btn-container">
            <button style={{ background: "linear-gradient(180deg, #3884C6 0%, #5DB256 100%)", padding: "12px 16px 12px 14px" }} onClick={() => prevTab()}><img src={prevBtnImg} alt="previous button" /></button>
            <span className="section-name">{sections[currentTabIdx]?.section}</span>	
            <button style={{ background: "linear-gradient(180deg, #3884C6 0%, #5DB256 100%)", padding: "12px 14px 12px 16px" }} onClick={() => nextTab()}><img src={nextBtnImg} alt="next button" /></button>
          </div>
        </div>

        {selectedMemberData[currentTabIdx]?.questions?.map((q, idx) => {
          return <div className={(idx % 2 === 0) ? "even question-answer" : "odd question-answer"}>
            <p className="question">{q.question}</p>
             <p className={answerTextColors(q.answerNum)}>{q.answerTxt}</p>
          </div>
        })}
      </div> */}

            {windowWidth < 551 && <h3 style={{ textAlign: "center", fontSize: "18px", color: "#27282A" }}>INDIVIDUAL SCORES</h3>}
            {
							sectionsAndSubScores.map((data, index) => {
								
								let memberSection 		= [];
								if(index == 0) { 
									memberSection1.forEach((member) => {
										console.log(member);
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});									
								} else if(index == 1) {
									memberSection2.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 2) {
									memberSection3.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 3) {
									memberSection4.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								}
								console.log(memberSection);

								return <div key={index} className="members-answered">
									<p className="section" onClick={() => toggleSectionDetails(index + 1)}>
										{data.section}
										{/* <a className="section-toggle" onClick={() => toggleSectionDetails(index + 1)}> */}
										<a className="section-toggle">
											{ toggleSectionIcon(index + 1) }
										</a>
									</p>
									<div style={ toggleSectionDisplay(index + 1) }>
										{
											memberSection.map((data, index) => (
												<div key={index} className="answered-detail"><p>{ removeTags(data.question) }</p> {showAnswerText(data.answer, data.id)}</div>
											)
										)}
									</div>
								</div>
							}
						)}

        </div>

        {/* {
          sections.map((data, index) => {

            let memberSection = [];
            if (index === 0) {
              memberSection1.forEach((member) => {
                // console.log(member);
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            } else if (index === 1) {
              memberSection2.forEach((member) => {
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            } else if (index === 2) {
              memberSection3.forEach((member) => {
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            } else if (index === 3) {
              memberSection4.forEach((member) => {
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            }
            // console.log(memberSection);

            return <div className="members-answered">
              <p className="section">
                {data.section}
                <a className="section-toggle" onClick={() => toggleSectionDetails(index + 1)}>
                  {toggleSectionIcon(index + 1)}
                </a>
              </p>
              <div style={toggleSectionDisplay(index + 1)}>
                {
                  memberSection.map((data, index) => (
                    <div key={index} className="answered-detail"><p>{data.question}</p> {showAnswerText(data.answer, data.id)}</div>
                  )
                  )}
              </div>
            </div>
          }
          )} */}

        <div className="hurdles-enablers" style={{ margin: windowWidth > 650 ? "45px auto 15px" : "20px auto 15px", width: "90%", maxWidth: "780px" }}>
          <h2 style={{ color: '#77A07C' }}>CHURCH GROWTH PLAN</h2>
          <div className="enablers">
            <ul>
              {
                // selected team members answer for additional question (id 426) 
                <li>{individualAnswer[0] || <>&#8212;</>}</li>}
            </ul>
          </div>
        </div>

        <div style={{ height: "40px" }}></div>

      </div>

    </div>
  )
}

export default DashboardIndividual;
