import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
// import BleatLogo from '../../Assets/Images/logo-bleat.svg';
// import BleatLogo from '../../Assets/Images/logo-bleat-new.png';
import BleatLogo from '../../Assets/Images/built-by-bleat-white.png';

import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Footer = props => {

  const { windowWidth } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAssessmentEmbedded, setIsAssessmentEmbedded] = useState(false);

  let display = searchParams.get("display");
  console.log("display:", display);

  useEffect(() => {
    if(display === "embedded" || display === "preview") {
      setIsAssessmentEmbedded(true);
    }
  }, [display]);

  if (isAssessmentEmbedded) return <div style={{ margin: "0 auto", padding: "15px 0 15px" }}></div>;
  else return <Fragment>
    <footer>
      {/*<div className="footer-logos-div">
        <div className="footer-logo-left">
          <img src={ChurchFuelLogo} alt="Church Fuel Logo" />
        </div>
        <div className="footer-logo-right">
          <img src={BleatLogo} alt="Bleat Logo" />
        </div>
      </div>*/}
      { windowWidth > 830 ? null:
        <div className='footer-logos-div'>
          <div className='footer-logo-center'>
          <img src={BleatLogo} alt='Bleat Logo' />
          </div>
        </div>
      }
    </footer>
  </Fragment>
}

export default Footer;