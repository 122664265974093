import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link, useParams } from "react-router-dom";
import Tabs from './Tabs';
import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import '../App.scss'

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import '../App.scss';
import ResultsSectionBarGraph from '../Components/Results/ResultsSectionBarGraph';
import Results from './Results';
import UrgencyScore from '../Components/Dashboard/UrgencyScore';
import DashboardIndividual from '../Components/Dashboard/DashboardIndividual';
import DashboardGroup from '../Components/Dashboard/DashboardGroup';
import DashboardToggle from '../Components/Dashboard/DashboardToggle';

const Dashboard = (props) => {

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [currentView, setCurrentView] = useState(0);
  const [apiData, setApiData] = useState(null);

  const [toggleIndividual, setToggleIndividual] = useState(0);
  const [toggleSection, setToggleSection] = useState(1);
  const [toggleMore, setToggleMore] = useState(0);

  const [selected, setSelected] = useState(0);
  const [tops, setTops] = useState([]);
  const [bottoms, setBottoms] = useState([]);

  const [score, setScore] = useState(0);
  const [urgencyVote1, setUrgencyVote1] = useState(0);
  const [urgencyVote2, setUrgencyVote2] = useState(0);
  const [urgencyVote3, setUrgencyVote3] = useState(0);
  const [urgencyVote4, setUrgencyVote4] = useState(0);
  const [urgencyVote5, setUrgencyVote5] = useState(0);
  const [memberSection1, setMemberSection1] = useState([]);
  const [memberSection2, setMemberSection2] = useState([]);
  const [memberSection3, setMemberSection3] = useState([]);
  const [memberSection4, setMemberSection4] = useState([]);

  const [memberScore, setMemberScore] = useState(0);
  const [memberName, setMemberName] = useState(0);
  // const [memSubScore1, setMemSubScore1] = useState(0);
  // const [memSubScore8, setMemSubScore8] = useState(0);
  const [memUrgencyScore, setMemUrgencyScore] = useState(0);

  let urgencyPercent = 60.0;
  if (window.innerWidth > 650) {
    urgencyPercent = 63.9;
  }

  useEffect(() => {

    const fetchResults = () => {

      fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          setApiData(data);
          setScore(data.score);

          // console.log(data.questionInfo);

          setSelected(data.assessmentInfo[0].id);
          setMemberScore(data.assessmentInfo[0].score);
          setMemberName(data.assessmentInfo[0].fullName);
          setMemUrgencyScore(data.assessmentInfo[0].addAnswer1);


          let section1Questions = data.questionInfo.filter(function (obj) { return obj.sectionId === 1; });
          section1Questions.forEach((question) => {
            let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id === question.id });
            question.answer = answer.answer;
          });
          setMemberSection1(section1Questions);

          let section2Questions = data.questionInfo.filter(function (obj) { return obj.sectionId === 2; });
          section2Questions.forEach((question) => {
            let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id === question.id });
            question.answer = answer.answer;
          });
          setMemberSection2(section2Questions);

          let section3Questions = data.questionInfo.filter(function (obj) { return obj.sectionId === 3; });
          section3Questions.forEach((question) => {
            let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id === question.id });
            question.answer = answer.answer;
          });
          setMemberSection3(section3Questions);

          let section4Questions = data.questionInfo.filter(function (obj) { return obj.sectionId === 4; });
          section4Questions.forEach((question) => {
            let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id === question.id });
            question.answer = answer.answer;
          });
          setMemberSection4(section4Questions);

          let tops = data.questionTop.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
          let bottoms = data.questionBottom.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));

          let urgVote1 = 0;
          let urgVote2 = 0;
          let urgVote3 = 0;
          let urgVote4 = 0;
          let urgVote5 = 0;

          data.assessmentInfo.forEach((member) => {
            if (member.addAnswer1 === 1) {
              urgVote1++;
            } else if (member.addAnswer1 === 2) {
              urgVote2++;
            } else if (member.addAnswer1 === 3) {
              urgVote3++;
            } else if (member.addAnswer1 === 4) {
              urgVote4++;
            } else if (member.addAnswer1 === 5) {
              urgVote5++;
            }
          });

          setUrgencyVote1(urgVote1);
          setUrgencyVote2(urgVote2);
          setUrgencyVote3(urgVote3);
          setUrgencyVote4(urgVote4);
          setUrgencyVote5(urgVote5);

          setTops(data.questionTopV3.slice(0, 3));
          setBottoms(data.questionBottomV3.slice(0, 3));
          setIsLoading(false);

          // console.log(tops);
          // console.log(bottoms);
          // console.log(questions);
        } else {
          // setOpenSnackbar(true);
        }
      });
    }
    // document.body.style.backgroundColor = "#1F2A44";
    fetchResults();
  }, [id]);


  const subScore1 = parseInt(apiData?.subScore1);
  const subScore2 = parseInt(apiData?.subScore2);
  const subScore3 = parseInt(apiData?.subScore3);
  const subScore4 = parseInt(apiData?.subScore4);
  const subScore5 = parseInt(apiData?.subScore5);
  const subScore6 = parseInt(apiData?.subScore6);
  const subScore7 = parseInt(apiData?.subScore7);
  const subScore8 = parseInt(apiData?.subScore8);
  const subScore9 = parseInt(apiData?.subScore9);

  const memSubScore1 = parseInt(apiData?.assessmentInfo[0]?.subScore1);
  const memSubScore2 = parseInt(apiData?.assessmentInfo[0]?.subScore2);
  const memSubScore3 = parseInt(apiData?.assessmentInfo[0]?.subScore3);
  const memSubScore4 = parseInt(apiData?.assessmentInfo[0]?.subScore4);
  const memSubScore5 = parseInt(apiData?.assessmentInfo[0]?.subScore5);
  const memSubScore6 = parseInt(apiData?.assessmentInfo[0]?.subScore6);
  const memSubScore7 = parseInt(apiData?.assessmentInfo[0]?.subScore7);
  const memSubScore8 = parseInt(apiData?.assessmentInfo[0]?.subScore8);
  const memSubScore9 = parseInt(apiData?.assessmentInfo[0]?.subScore9);

  const urgencyScore = apiData?.urgencyScore;
  const members = apiData?.assessmentInfo;
  const questions = apiData?.questionInfo;
  const sections = apiData?.sectionInfo;
  const hurdles = apiData?.hurdles;
  const enablers = apiData?.enablers;

  // console.log(apiData);
  // console.log(apiData?.assessmentInfo[0]?.subScore1);
  // console.log(apiData?.assessmentInfo[0]?.subScore9);
  // console.log();

  const memScores = [memSubScore1, memSubScore2, memSubScore3, memSubScore4, memSubScore5, memSubScore6, memSubScore7, memSubScore8, memSubScore9];

  const subscores = [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9];
  
  const scores = subscores.filter(score => score !== false)

  // const subscores = { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 };
  // console.log(urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5);

  const updateIndividualAssessment = (e) => {
    let memberId = e.target.value;
    changeIndividualAssessment(memberId);
  }

  const changeIndividualAssessment = (id) => {
    let member = members.find(function (obj) { return obj.id === id; });
    // console.log(member);
    // console.log(questions);
    let sectionIds = [];
    sections.forEach((section) => {
      sectionIds.push(section.id);
    });
    console.log(sectionIds);
    setSelected(member.id);
    setMemberScore(member.score);
    setMemberName(member.fullName);
    // setMemSubScore1(member.subScore1);
    // setMemSubScore2(member.subScore2);
    // setMemSubScore3(member.subScore3);
    // setMemSubScore4(member.subScore4);
    setMemUrgencyScore(member.addAnswer1);

    let section1Questions = questions.filter(function (obj) { return obj.sectionId === sectionIds[0]; });
    section1Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id === question.id });
      question.answer = answer.answer;
    });

    setMemberSection1(section1Questions);

    let section2Questions = questions.filter(function (obj) { return obj.sectionId === sectionIds[1]; });
    section2Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id === question.id });
      question.answer = answer.answer;
    });
    setMemberSection2(section2Questions);

    let section3Questions = questions.filter(function (obj) { return obj.sectionId === sectionIds[2]; });
    section3Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id === question.id });
      question.answer = answer.answer;
    });
    setMemberSection3(section3Questions);

    let section4Questions = questions.filter(function (obj) { return obj.sectionId === sectionIds[3]; });
    section4Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id === question.id });
      question.answer = answer.answer;
    });
    setMemberSection4(section4Questions);

  }

  const toggleSectionDetails = (id) => {
    if (id === toggleSection) {
      setToggleSection(0);
    } else {
      setToggleSection(id);
    }
  }

  // const toggleChange = (e) => {
  //   setToggleSection(!e.target.checked);
  //   if (e.target.checked) {
  //     toggleTeamIndividual(0);
  //   } else {
  //     toggleTeamIndividual(1)
  //   }
  // }

  const urgencyComponentProps = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, questions };

  const dashboardIndividualProps = { selected, updateIndividualAssessment, members, memberScore, sections, questions, memScores, memberSection1, memberSection2, memberSection3, memberSection4, toggleSection, toggleSectionDetails }

  const dashboardGroupProps = { hurdles, enablers, sections, questions, members, bottoms, tops, score, scores, toggleMore, setToggleMore, urgencyComponentProps }
  
  const dashboardToggleProps = { members, currentView, setCurrentView, setToggleIndividual, changeIndividualAssessment, toggleSection, toggleSectionDetails }

  if (isLoading) {
    return (
      <div className="container dashboard loading">
        <CircularProgress style={{ color: '#5DB256' }} size={60} />
      </div>)
  } else {
    return (
      <>
        
        {/* <ResultsSectionBarGraph {...{ subscores }} /> */}
        {/* <DashboardGroup {...{ dashboardGroupProps }} /> */}

        {/* Toggle between Team and Individual */}
        <DashboardToggle {...{ dashboardToggleProps }} />
        {currentView === 1 ? (
          <div>
            <DashboardIndividual {...{ dashboardIndividualProps }} />
          </div>
        ) : (
          <div>
            <DashboardGroup {...{ dashboardGroupProps }} />
          </div>
        )}
      </>
    )
  }
}
export default Dashboard;

