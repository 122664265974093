import React from 'react'
import Switch from '@mui/material/Switch';

const DashboardToggle = ({ dashboardToggleProps }) => {

  const { members, currentView, setCurrentView, setToggleIndividual, changeIndividualAssessment, toggleSection, toggleSectionDetails } = dashboardToggleProps;

  const toggleTeamIndividual = (tab) => {
    if (tab === 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
      changeIndividualAssessment(members[0].id)
    }
  }

  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    console.log("checked", e.target.checked);
    console.log("toggleSection", toggleSection);
    if (e.target.checked) {
      toggleTeamIndividual(0);
      toggleSectionDetails(0)
    } else {
      toggleTeamIndividual(1)
      toggleSectionDetails(1)
    }
  }

  return (
    <>
      <div className="individual-group">
        <button onClick={() => toggleTeamIndividual(1)} className={currentView === 0 ? "active left" : "left"}>TEAM</button>
        <button onClick={() => toggleTeamIndividual(0)} className={currentView === 1 ? "active right" : "right"}>INDIVIDUAL</button>
      </div>

      <div className="toggle-individual-group">
        <div className={currentView === 0 ? "active left" : "left"}>TEAM</div>
        <Switch
          checked={!toggleSection}
          onChange={(e) => toggleIndividualChange(e)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <div className={currentView === 1 ? "active right" : "right"}>INDIVIDUAL</div>
      </div>
    </>
  )
}

export default DashboardToggle;
